import { defineComponent, onMounted, onUnmounted, computed } from '@vue/composition-api';
import RegionsBlock from '@/GeoPages/components/RegionsBlock.vue';
import CitiesBlock from '@/GeoPages/components/CitiesBlock.vue';
import { useMeta } from '@/shared/composables/useMeta';
import { SITE_URL } from '@/App.vue';
import { GeoPagesRoutes } from '@/GeoPages/geopages.const';
import EventBus from '@/shared/services/eventBus';
import useStructuredData from '@/shared/composables/useStructuredData';
import PageFAQ from '@/components/PageFAQ.vue';
export default defineComponent({
    components: {
        PageFAQ,
        RegionsBlock,
        CitiesBlock
    },
    setup(_, context) {
        const { root } = context;
        const faqQuestions = computed(() => root.$t('geo_pages.locations_page.faq', {
            returnObjects: true
        }));
        const { injectStructuredData, ejectStructuredData } = useStructuredData();
        const injectData = (structuredData) => {
            const data = {
                '@context': 'https://schema.org',
                '@graph': []
            };
            data['@graph'].push(structuredData);
            injectStructuredData({
                ...data
            });
        };
        EventBus.$on('onBreadcrumbsReady', (data) => {
            injectData(data);
        });
        onMounted(() => {
            useMeta({
                title: root.$i18n.t('meta.locations.title_tag').toString(),
                description: root.$i18n.t('meta.locations.description_tag').toString(),
                url: `${SITE_URL}/${GeoPagesRoutes.CITIES}`,
                noIndex: false
            });
        });
        onUnmounted(() => {
            ejectStructuredData();
        });
        return { faqQuestions, injectData };
    }
});
